:root {
  --text-base-size: 1rem;
  --text-scale-ratio: 1.3;

  --text-sm: calc(1rem / var(--text-scale-ratio));
  --text-md: calc(1rem * var(--text-scale-ratio));
  --text-lg: calc(1rem * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(1rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxl: calc(
    1rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xxxl: calc(
    1rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );
}

body {
  color: $gray-800;
}

h1,
h2 {
  font-weight: 600 !important;
}

h1,
.text-xxl {
  font-size: var(--text-xxl);
}

h2,
.text-xl {
  font-size: var(--text-xl);
}

h3,
.text-lg {
  font-size: var(--text-lg);
}

.text-md {
  font-size: var(--text-md);
}

.text-rg {
  font-size: var(--text-base-size);
}

.text-sm {
  font-size: var(--text-sm);
}
