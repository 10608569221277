// Variable overrides
@import "color-palette";

//Body rules
body {
  background-color: $gray-50 !important;
}

/*-------------------------Global components rules-------------------------*/

.border-shadow {
  box-shadow: 0 4px 16px 0 rgba(50, 56, 63, 0.09) !important;
  border-color: $transparent !important;
}

.left-child {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.right-child {
  border-bottom-right-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.top-child {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.bottom-child {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.box {
  border-radius: 0.75rem !important;
  padding: 0.2rem 0.75rem 0.75rem 0.75rem !important;
}

/* Titles */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: $gray-800;
}

a {
  color: rgba(var(--color-main-500), var(--text-opacity));
  cursor: pointer;
}

/* override bootstrap button styles */
.btn {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0.4rem 0.7rem !important;
  border-radius: 0.5rem !important;
  transition: 200ms all ease !important;
  font-weight: 500 !important;
  box-shadow:
    0px 2px 5px 0px rgba(60, 66, 87, 0.08),
    0px 1px 1px 0px rgba(0, 0, 0, 0.12) !important;

  &:focus {
    outline: none !important;
    box-shadow: 0 0 0 3px rgba(var(--color-main-300), var(--text-opacity)) !important;
  }

  &:active {
    box-shadow: none !important;
  }

  img {
    margin-right: 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}

.btn.icon-only {
  padding: 0.5rem 0.7rem !important;

  img {
    margin-right: 0 !important;
  }
}

.btn.icon-right img {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.btn-primary {
  background-color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  color: $white !important;
  border: none !important;

  &:hover {
    outline: 1px solid #d5dbe1 !important;
  }

  img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(272deg) brightness(108%) contrast(102%);
  }
}

.btn-secondary {
  background-color: $white !important;
  border: 1px solid #d5dbe1 !important;
  color: #414552 !important;

  &:hover {
    background-color: #f6f8fa !important;
  }

  img {
    filter: invert(27%) sepia(7%) saturate(964%) hue-rotate(189deg) brightness(94%) contrast(96%) !important;
  }
}

.btn-ghost-secondary {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #414552 !important;

  &:hover,
  &:focus {
    background-color: rgba(55, 53, 47, 0.08) !important;
    box-shadow: none !important;
  }

  &:active {
    background-color: rgba(55, 53, 47, 0.16) !important;
  }

  img {
    filter: invert(25%) sepia(10%) saturate(846%) hue-rotate(188deg) brightness(93%) contrast(87%);
  }
}

// if using custom button, we want to prevent filter and margin in image
.image-no-filter img {
  filter: none !important;
  margin: 0 !important;
}

// btn group
.btn-group {
  display: flex;
  .btn:first-child {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .btn:last-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-icon-on-hover {
  img {
    opacity: 0;
  }
  &:hover img {
    opacity: 1;
  }
}

// tabs
.nav-wrapper {
  display: flex;
  list-style: none;
  border-bottom: 1px solid $gray-300;
  padding: 0;
}

.nav-wrapper.nav-stretch .nav-element {
  flex: 1;
  text-align: center;
}

.nav-element {
  font-weight: 500;
  padding: 0.5rem;

  a {
    user-select: none;
    font-size: var(--text-md);
    transition: 100ms all ease;
    color: $gray-700 !important;
    text-decoration: none !important;
    display: block;
    padding: 0.2rem 0.5rem;
    border-bottom: 2px solid transparent;
    border-radius: 0.5rem;

    &:hover {
      background-color: $gray-200 !important;
    }
  }
}

.nav-element.active {
  border-bottom: 2px solid rgba(var(--color-main-500), var(--text-opacity));
  a {
    color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  }
}

.nav-sm a {
  font-size: var(--text-base-size);
}

// Refacto button below

.btn-group {
  .btn-secondary {
    &:active,
    &.active {
      box-shadow: none !important;
    }
  }
}

/* Inputs */

.simple-input {
  border-radius: 0.75rem !important;
  box-shadow:
    0px 2px 5px 0px rgba(60, 66, 87, 0.08),
    0px 1px 1px 0px rgba(0, 0, 0, 0.12);

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--color-main-500), 0.5) !important;
  }
}

.input-group {
  flex-wrap: nowrap !important;
  box-shadow: 0 4px 16px 0 rgba(50, 56, 63, 0.1) !important;
  border-radius: 0.75rem !important;

  i {
    color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  }

  fa-icon {
    color: rgba(var(--color-main-500), var(--text-opacity));
  }

  .input-group-prepend {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;

    .input-group-text {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
      color: rgba(var(--color-main-500), var(--text-opacity));
    }
  }

  .simple-input-prepend {
    border-bottom-right-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--color-main-500), 0.5) !important;
    }
  }

  .input-group-append {
    border-bottom-right-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;

    .input-group-text {
      border-bottom-right-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
      color: rgba(var(--color-main-500), var(--text-opacity));
    }
  }

  .simple-input-append {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--color-main-500), 0.5) !important;
    }
  }
}

.dropdown {
  display: inline-block;

  i {
    color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  }

  fa-icon {
    color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  }

  .dropdown-menu {
    width: 100%; //TODO(Riad) : find the modals
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .dropdown-item {
    color: $quarternary !important;

    &:active,
    &.active {
      background-color: darken($secondary, 10%) !important;
      border-color: $transparent !important;
    }
  }

  .dropdown-img {
    width: 98%;
    height: 100%;
    object-fit: contain;
  }

  .dropdown-button {
    width: 95%;
    color: $quarternary;
  }

  .dropdown-button::after {
    margin-left: 0.255em;
    margin-top: 0.5em;
    vertical-align: super;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    right: 1em;
  }
}

.asset-alt {
  font-size: 3rem;
  color: $quarternary;
}

/* Tables */
.table {
  th {
    border-top: none !important;
    color: $quarternary;
    text-align: center;
  }

  td {
    color: $quarternary;
    vertical-align: middle !important;
    text-align: center;
  }

  tfoot {
    text-align: left !important;
  }

  .sticky-header,
  .sticky-header-top {
    position: sticky;
    z-index: 100;
    background-color: $dark-secondary;
  }

  .sticky-header {
    top: 3.5rem;
  }

  .sticky-header-top {
    top: -2rem;
  }
}

/* Cards */

.card-perso {
  display: inline-block !important;
  background-color: $secondary;
  padding: 0rem;
  width: 18rem;
  cursor: pointer;

  .card-perso-header,
  .card-perso-body,
  .card-perso-footer {
    background-color: $transparent;
    border-color: $transparent;
  }

  .card-perso-header {
    color: $quarternary;
    vertical-align: middle;
    padding-bottom: 0.375rem;

    h5 {
      display: inline-block;
      color: $quarternary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
      font-weight: 600;
    }

    .icon-prepend {
      display: inline-block;
      padding: 0.15rem;
      padding-left: 0rem;
      padding-right: 0.75rem;
    }
  }

  .card-perso-body {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    p {
      margin-bottom: 0.5rem;
    }
  }
}

.collapse-card {
  background-color: $transparent !important;
  border-color: $transparent !important;
  width: 100% !important;
}

.collapse-card-header {
  color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  padding-left: 0rem;
  padding-right: 0rem;

  h5 {
    color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  }
}

.create-card {
  display: table;
  color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;

  .add-buttons-group {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}

/* Modals */
.modal-footer {
  border-top: $transparent !important;
}

.modal-title {
  color: $secondary;
}

.pagination {
  display: inline-flex !important;
}

.main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.split-button-right {
  color: $quarternary !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: 8px 4px 16px -10px rgb(50 56 63 / 10%) !important;
}

.split-button-left {
  color: $quarternary !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.amazon-login-button:hover {
  filter: brightness(90%);
}

.darker-button:hover {
  filter: brightness(90%);
}
