// grid-stack CSS
@import "gridstack/dist/gridstack.min.css";

.grid-stack-item-content {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  background-color: $secondary;
  border-radius: 1rem;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
  align-self: stretch;
  justify-self: stretch;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid rgb(222, 224, 228);
}

gridstack-item:hover {
  > .grid-stack-item-content {
    box-shadow: 0 4px 16px 0 rgba(50, 56, 63, 0.5);
    border: rgba(var(--color-main-500), var(--text-opacity)) 1px solid;
  }
}

.ui-resizable-se {
  transform: none !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgd2lkdGg9IjYiIGhlaWdodD0iNiI+PHBhdGggZD0iTTYgNkgwVjQuMmg0LjJWMEg2djZ6IiBvcGFjaXR5PSIuMzAyIi8+PC9zdmc+) !important;
}
