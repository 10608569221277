// Ag-grid
@import "@ag-grid-community/styles/ag-grid.css";
// Core grid CSS, always needed
@import "@ag-grid-community/styles/ag-theme-quartz.min.css"; // Optional theme CSS

// Ag grid custom css
.grid-row-footer {
  background-color: #f8f8f8 !important; //Same as ag grid header
  font-weight: bold;
}

.header-pre-wrap {
  white-space: pre-wrap !important;
}

.grid-row-leaf {
  background-color: #f8f8f8 !important;
}

.ag-theme-quartz {
  // custom checkbox color for ag-grid
  --ag-checkbox-checked-color: rgba(var(--color-main-500));
  --ag-checkbox-indeterminate-color: grey;
}

.base-theme.ag-theme-quartz {
  --ag-header-background-color: rgb(255, 255, 255);
  --ag-row-hover-color: rgb(240, 240, 240);
}

.ag-base-cell {
  display: flex;
  align-items: center;
}

.actions-button-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}
