//Color palette
$cyan: darken(#6cc0ae, 10%);
$white: white;
$secondwhite: darken($white, 1.5%);
$darkgray: #333a40;
$magenta: #8e6ba0;
$gray: #969696;
$darkblue: #181d4d;
$yellow: rgb(240, 173, 78);
$red: #d9534f;
$blue: #7b9fe8;
$beige: #f9b1c7;

// Colors id
//$primary: $cyan !default;
$primary: $cyan !default;
$light-primary: lighten($primary, 30%) !default;
$x-light-primary: lighten($primary, 43%) !default;
$x-light-danger: lighten($red, 32%) !default;
$dark-primary: darken($primary, 5%) !default;
$secondary: $white !default;
$dark-secondary: darken($secondary, 10%) !default;
$tertiary: $gray !default;
$quarternary: $darkblue !default;
$quinary: $secondwhite !default;
$senary: $yellow !default;
$septenary: $blue !default;
$octonary: $beige !default;
$nonary: $magenta !default;
$warning: $yellow !default;
$danger: $red !default;
$info: $gray !default;
$success: $cyan !default;
$light-success: lighten($success, 20%) !default;
$x-light-success: lighten($success, 40%) !default;
$dark-warning: darken($warning, 10%) !default;

$transparent: transparent;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  quarternary: $quarternary,
  quinary: $quinary,
  senary: $senary,
  septenary: $septenary,
  octonary: $octonary,
  nonary: $nonary,
  warning: $warning,
  danger: $danger,
  info: $info,
  success: $success,
  light-primary: $light-primary,
  light-success: $light-success,
  dark-secondary: $dark-secondary,
  dark-primary: $dark-primary,
  dark-warning: $dark-warning,
);

$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bebebe;
$gray-500: #9f9f9f;
$gray-600: #767676;
$gray-700: #626262;
$gray-800: #424242;
