// If you want to override variables do it here
@import "variables";

@import "material-palette";

@import "ngx-toastr/toastr";


// Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/card";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/list-group";

// If you want to add something do it here
@import "custom";
// @import "sizes";
@import "typography";

// angular overlay
@import "@angular/cdk/overlay-prebuilt.css";

// Ag-grid
@import "ag-grid";

// Gristack
@import "gridstack";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.collapse-alias {
  @apply collapse;
}

* {
  font-family: "Inter", sans-serif;
}

button {
  -webkit-appearance: button;
  background-color: initial;
  background-image: none;
}

:root .m19-theme,
.southparc-theme {
  --text-opacity: 1;
  --color-main-50: 247, 250, 249;
  --color-main-100: 243, 250, 248;
  --color-main-200: 213, 242, 233;
  --color-main-300: 171, 228, 212;
  --color-main-400: 122, 206, 186;
  --color-main-500: 75, 174, 153;
  --color-main-600: 53, 151, 133;
  --color-main-700: 40, 121, 107;
  --color-main-800: 35, 98, 88;
  --color-main-900: 32, 79, 72;
}

:root .signalytics-theme {
  --text-opacity: 1;
  --color-main-50: 254, 242, 243;
  --color-main-100: 254, 229, 231;
  --color-main-200: 252, 207, 212;
  --color-main-300: 249, 168, 176;
  --color-main-400: 245, 119, 135;
  --color-main-500: 236, 71, 96;
  --color-main-600: 216, 38, 73;
  --color-main-700: 182, 26, 61;
  --color-main-800: 153, 24, 57;
  --color-main-900: 131, 24, 55;
  --color-main-950: 73, 8, 25;
}
:root .etail-agency-theme {
  --text-opacity: 1;
  --color-main-50: 244, 241, 255;
  --color-main-100: 237, 229, 255;
  --color-main-200: 220, 206, 255;
  --color-main-300: 195, 167, 255;
  --color-main-400: 168, 118, 255;
  --color-main-500: 143, 63, 255;
  --color-main-600: 133, 24, 255;
  --color-main-700: 121, 7, 250;
  --color-main-800: 101, 5, 210;
  --color-main-900: 84, 6, 172;
  --color-main-950: 57, 0, 132;
}

:root .amzppc-theme {
  --text-opacity: 1;
  --color-main-50: 255, 251, 234;
  --color-main-100: 255, 242, 197;
  --color-main-200: 255, 230, 133;
  --color-main-300: 255, 210, 70;
  --color-main-400: 255, 189, 27;
  --color-main-500: 255, 153, 0;
  --color-main-600: 226, 114, 0;
  --color-main-700: 187, 77, 2;
  --color-main-800: 152, 59, 8;
  --color-main-900: 124, 49, 11;
}

:root .ppclogic-theme {
  --text-opacity: 1;
  --color-main-50: 252, 245, 235;
  --color-main-100: 250, 234, 215;
  --color-main-200: 245, 213, 175;
  --color-main-300: 239, 193, 134;
  --color-main-400: 234, 172, 94;
  --color-main-500: 229, 151, 54;
  --color-main-600: 183, 121, 43;
  --color-main-700: 137, 91, 32;
  --color-main-800: 92, 60, 22;
  --color-main-900: 46, 30, 11;
}

:root .m2asolutions-theme {
  --text-opacity: 1;
  --color-main-50: 233, 233, 233;
  --color-main-100: 212, 211, 211;
  --color-main-200: 168, 167, 168;
  --color-main-300: 125, 124, 124;
  --color-main-400: 81, 80, 81;
  --color-main-500: 38, 36, 37;
  --color-main-600: 30, 29, 30;
  --color-main-700: 23, 22, 22;
  --color-main-800: 15, 14, 15;
  --color-main-900: 8, 7, 7;
}

:root .sellingz-theme {
  --text-opacity: 1;
  --color-main-50: 253, 237, 235;
  --color-main-100: 250, 218, 214;
  --color-main-200: 246, 181, 174;
  --color-main-300: 241, 145, 133;
  --color-main-400: 237, 108, 93;
  --color-main-500: 232, 71, 52;
  --color-main-600: 186, 57, 42;
  --color-main-700: 139, 43, 31;
  --color-main-800: 93, 28, 21;
  --color-main-900: 46, 14, 10;
}

:root .upcounting-theme {
  --text-opacity: 1;
  --color-main-50: 230, 249, 255;
  --color-main-100: 204, 242, 255;
  --color-main-200: 153, 229, 255;
  --color-main-300: 102, 217, 255;
  --color-main-400: 51, 204, 255;
  --color-main-500: 0, 191, 255;
  --color-main-600: 0, 153, 204;
  --color-main-700: 0, 115, 153;
  --color-main-800: 0, 76, 102;
  --color-main-900: 0, 38, 51;
}

:root .astra-sellrbox-theme {
  --text-opacity: 1;
  --color-main-50: 243, 243, 255;
  --color-main-100: 233, 233, 254;
  --color-main-200: 215, 216, 253;
  --color-main-300: 185, 182, 252;
  --color-main-400: 149, 141, 248;
  --color-main-500: 114, 95, 243;
  --color-main-600: 81, 45, 232;
  --color-main-700: 80, 43, 214;
  --color-main-800: 67, 36, 179;
  --color-main-900: 56, 31, 147;
}
