@import "./color-palette";

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme("borderColor.DEFAULT", currentColor);
}

ul {
  padding: 0;
}

// app switch toggle
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: rgba(var(--color-main-500), var(--text-opacity));
  background-color: rgba(var(--color-main-500), var(--text-opacity));
}

// mat slide toggle
.mdc-switch:enabled .mdc-switch__track::after {
  background: rgba(var(--color-main-500), var(--text-opacity)) !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: rgba(var(--color-main-500), var(--text-opacity)) !important;
}

.text-primary {
  color: rgba(var(--color-main-500), var(--text-opacity)) !important;
}

a:hover {
  color: rgba(var(--color-main-700), var(--text-opacity));
}

// modal headers
.bg-primary {
  background-color: rgba(var(--color-main-500), var(--text-opacity)) !important;
}

//overlays
.alert-primary {
  color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  background-color: rgba(var(--color-main-100), var(--text-opacity)) !important;
  border-color: rgba(var(--color-main-300), var(--text-opacity)) !important;
}

// mat radio
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: rgba(var(--color-main-500), var(--text-opacity)) !important;
}

// mat radio
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: rgba(var(--color-main-500), var(--text-opacity)) !important;
}

.app,
app-default-layout,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  margin: 0;
}

h5 {
  font-size: 1.1rem;
}

.sidebar {
  z-index: 1040;
}

.mat-mdc-slide-toggle label {
  margin-bottom: 0 !important;
}

.list-separator {
  border-bottom: 1px solid $gray-300;
}

header {
  & {
    position: sticky !important;
    z-index: 1020;
    width: 100%;
    padding: 0 !important;
    background-color: #fff;
    flex-direction: row;
    margin: 0;
    box-shadow: 0 4px 16px 0 rgba(50, 56, 63, 0.08) !important;
    align-content: center;
  }

  .nav-sub {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .nav-toggle {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 1.2rem;
    color: #9c9c9c;
    margin-top: 0.3rem;
    transition: 0.1s;

    &:hover {
      color: #808080;
    }
  }

  .navbar-brand {
    width: 155px;
    display: inline-flex;
    justify-content: center;
    margin: 0;
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0;
    text-align: center;
  }

  .nav-item button {
    margin: 0 auto;
  }
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
}

button.link {
  background-color: transparent;
  border: none;
  padding: 0;
}

.no-underline {
  text-decoration: none !important;
}

.link {
  color: rgba(var(--color-main-500), var(--text-opacity));

  &:hover {
    text-decoration: underline;
  }
}

.main .container-fluid {
  padding: 0 30px;
}

.card {
  margin-bottom: 1.5rem;
}

.card-common {
  border: 1px solid $gray-200;
  box-shadow:
    0px 2px 5px 0px rgba(60, 66, 87, 0.08),
    0px 1px 1px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: #ffffff;
  border-radius: 0.3rem;
}

.form-control {
  border: 1px solid #e4e7ea;
}

.input-group-text {
  background-color: #f0f3f5;
  border: 1px solid #e4e7ea;
}

.input-block label {
  color: #414552;
  font-size: 0.9rem;
  margin-left: 0.3rem;
}

.input-block__hint {
  width: 100%;
  text-align: right;
  display: inline-block;
  margin-left: auto;
  font-size: 0.8rem;
  color: #414552;
  padding-right: 0.3rem;
}

.btn [class*=" icon-"],
.btn [class^="icon-"] {
  display: inline-block;
  margin-top: -2px;
  vertical-align: middle;
}

modal-container {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-primary .modal-header {
  color: #fff;
  background-color: rgba(var(--color-main-500), var(--text-opacity));
}

.modal-limit {
  max-height: 80vh;
  overflow: auto;
}

.big-modal {
  max-width: 70%;
}

@media (max-width: 1500px) {
  .big-modal {
    max-width: 90%;
  }
}

.modal-danger .modal-header {
  color: #fff;
  background-color: #d9534f;
}

.input-group-append .btn:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.input-group-text {
  border-radius: 0;
}

.metrics-header {
  position: sticky;
  z-index: 49;
  top: 0.25rem;
}

.desactivated {
  color: $tertiary !important;

  .btn-ghost-secondary {
    color: $tertiary !important;
  }
}

.activated {
  color: rgba(var(--color-main-500), var(--text-opacity)) !important;

  .btn-ghost-secondary {
    color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  }
}

.pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.text-color,
a.text-color:hover {
  color: $quarternary;
}

/*
Avoid hubspot widget to cover the scrollbar
*/
body > #hubspot-messages-iframe-container.widget-align-right {
  right: 15px !important;
}

.abs-delete-btn {
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: 50%;
  border: 2px solid rgba(var(--color-main-300), var(--text-opacity));
  cursor: pointer;
  display: none;

  img {
    width: 22px;
  }

  &:hover {
    background-color: rgba(var(--color-main-300), var(--text-opacity));
  }
}

.number {
  height: 26px;
  width: 26px;
  line-height: 26px; /* same as height for vertical alignment*/
  border-radius: 13px;
  font-weight: 600;
  text-align: center;
  background-color: rgba(var(--color-main-500), var(--text-opacity));
  color: $secondary;
}

.select-button-box {
  color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  cursor: pointer;
}

.positive {
  background-color: rgba(var(--color-main-500), var(--text-opacity));
}

.text-underline {
  text-decoration: underline;
}

.zero {
  background-color: $tertiary;
}

.warning {
  color: $warning;
}

.danger {
  color: $danger;
}

.no-outline,
.no-outline:focus {
  outline: none;
}

.break-all {
  word-break: break-all;
}

.filter-toggle.mat-mdc-icon-button {
  outline: none !important;
  width: 100% !important;
  height: auto !important;
  line-height: unset !important;
}

.table-striped-two tbody tr:nth-of-type(2n + 1) {
  background-color: rgb(242, 242, 242);
}

.table-striped-two tbody tr td {
  border-bottom-color: rgb(213, 213, 213);
}

.table-striped-two thead tr th {
  border-bottom-color: rgb(213, 213, 213);
}

.table-striped-four tbody tr:nth-of-type(4n + 1) {
  background-color: rgba(0, 0, 0, 0.05);
}

.center-mat-sort .mat-sort-header-container {
  justify-content: center;
  padding-left: 1rem;
}

.custom-btn-dark-hover:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.custom-btn-dark-hover:disabled {
  background-color: inherit !important;
}

.table-striped-four mat-row:nth-of-type(4n + 1) {
  background-color: rgb(242, 242, 242);
}

.table-striped-two mat-row:nth-of-type(2n + 1) {
  background-color: rgb(242, 242, 242);
}

.table-hover mat-row:hover {
  background-color: rgb(213, 213, 213);
}

.responsive-table {
  .mat-mdc-header-row {
    background-color: $dark-secondary;
  }

  .mat-mdc-header-row-no-space {
    top: -1.5rem !important;
    z-index: 200 !important;
  }

  .mat-mdc-footer-row {
    background-color: $dark-secondary;
  }

  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    color: $quarternary;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
  }

  .mat-mdc-cell:first-of-type,
  .mat-mdc-header-cell:first-of-type,
  .mat-mdc-footer-cell:first-of-type {
    padding-left: 0.5em;
  }

  .mat-mdc-cell:last-of-type,
  .mat-mdc-header-cell:last-of-type,
  .mat-mdc-footer-cell:last-of-type {
    padding-right: 0.5rem;
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    padding: 0.5em;
    font-size: 0.9rem;
  }

  .mat-mdc-row {
    min-height: unset;
    height: 48px !important;
  }

  .row-graph {
    height: unset !important;
  }
}

.mat-mdc-button-wrapper:not(.btn) {
  width: 100%;
}

.row-keyword {
  min-height: unset !important;
  height: 30px !important;
}

.product-title {
  /* for multiline truncate*/
  display: flex;
  align-items: center;
  height: 100%;
  text-overflow: ellipsis;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.right-mat-sort .mat-sort-header-container {
  justify-content: flex-end;
}

.flex-1 {
  flex: 2;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-half {
  flex: 0.5;
}

.flex-auto {
  flex: auto;
}

.line-height-1 {
  line-height: 1;
}

.disabled-noclick-light,
.disabled-noclick-hard {
  pointer-events: none;
  user-select: none;
}

.disabled-noclick-light {
  opacity: 0.5;
}

.disabled-noclick-hard {
  opacity: 0.3;
}

mat-mdc-paginator.left-paginator .mat-mdc-paginator-container {
  justify-content: flex-start !important;
}

.table-striped tbody tr:nth-of-type(odd) th {
  background-color: #f1f1f1 !important;
}

.table-striped tbody tr:nth-of-type(even) th {
  background-color: #fff !important;
}

.table-striped tbody tr th {
  border-top: 1px solid #dee2e6 !important;
}

.scrollbar-sm,
.scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.47rem;
    background-color: #f3f4f6;
  }

  &-sm::-webkit-scrollbar {
    width: 0.25rem;
    background-color: #f3f4f6;
  }

  &-sm::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 0.22rem;
  }

  &-sm::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

#chartjs-tooltip {
  & {
    opacity: 1;
    position: absolute;
    background: rgba(20, 20, 20, 0.8);
    color: white;
    padding: 3px;
    border-radius: 0.3rem;
    font-size: 0.75rem;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    pointer-events: none;
    white-space: nowrap;
  }

  &.left:before,
  &.right:before,
  &.center:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 99;
  }

  &.left:before {
    top: calc(50% + 5px);
    left: 0;
    border-top: 5px solid transparent;
    border-right: 5px solid rgba(20, 20, 20, 0.8);
    border-bottom: 5px solid transparent;
    -webkit-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }

  &.right:before {
    top: calc(50% - 5px);
    left: 100%;
    border-top: 5px solid transparent;
    border-left: 5px solid rgba(20, 20, 20, 0.8);
    border-bottom: 5px solid transparent;
  }

  &.center:before {
    top: -5px;
    left: calc(50% - 5px);
    border-left: 5px solid transparent;
    border-bottom: 5px solid rgba(20, 20, 20, 0.8);
    border-right: 5px solid transparent;
  }

  .legend-sq,
  .legend-rd {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 1px;
    vertical-align: middle;
  }

  .legend-sq {
    border-radius: 2px;
    border: 1px solid #ededed;
  }

  .legend-rd {
    border-radius: 50%;
  }
}

// hack to be able to show material tooltips in modals
.cdk-overlay-container {
  z-index: 10001 !important;
}

.gradient-0 {
  @apply bg-main-50;
}

.gradient-1 {
  @apply bg-main-50;
}

.gradient-2 {
  @apply bg-main-100;
}

.gradient-3 {
  @apply bg-main-200;
}

.gradient-4 {
  @apply bg-main-300;
}

.gradient-5 {
  @apply bg-main-400;
}

.gradient-6 {
  @apply bg-main-500 text-white;
}

.gradient-7 {
  @apply bg-main-600 text-white;
}

.gradient-8 {
  @apply bg-main-700 text-white;
}

.gradient-9 {
  @apply bg-main-800 text-white;
}

.div-product-img {
  height: 100%;
}

.mat-mdc-tooltip-panel {
  pointer-events: none;
}

.list-errors {
  position: absolute;
  z-index: 100;
  max-height: 100px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.mat-mdc-menu-panel {
  min-height: unset !important;
  max-width: 100% !important;
  border-radius: 0.4rem !important;
  box-shadow:
    0px 2px 5px 0px rgba(60, 66, 87, 0.08),
    0px 1px 1px 0px rgba(0, 0, 0, 0.12) !important;
  margin-top: 0.5rem;
  border: 1px solid #ececec;
}

.mat-mdc-menu-content {
  padding: 0 !important;
  border-radius: inherit !important;
}

.mat-menu-custom {
  padding: 0;

  .menu-item-custom {
    padding: 0.3rem 1.3rem;

    &:hover {
      background-color: #e8e8e8;
    }
  }
}

// Dropdown styles

.mat-mdc-menu-content {
  ul {
    list-style: none;
    margin: 0;
    padding: 3px 0;
    max-height: 30rem;
    min-width: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.dd-item {
  display: inline-flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  z-index: 1;

  a {
    text-decoration: none;
    color: unset;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:not(.dd-toggle) {
    margin: 0 3px; // outline
  }

  &:hover:not(.dd-toggle) {
    background-color: rgba(var(--color-main-100), var(--text-opacity));
  }

  img:not(.toggle-icon) {
    flex-basis: 18px;
    height: 18px;
  }

  .item-text {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:focus,
  &:active {
    outline: 3px solid rgba(var(--color-main-300), var(--text-opacity));
  }
}

.dd-input-container {
  margin-top: 1px; // to see mat panel border radius
  padding: 0.3rem 0.4rem;
  border-bottom: 1px solid #e3e3e3;
}

// native checkbox reset style
.form-checkbox {
  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  border-color: rgb(var(--color-gray-500) / var(--border-opacity, 1));
  border-width: 1px;
  color: #2563eb;
  display: inline-block;
  flex-shrink: 0;
  height: 1rem;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 1rem;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: currentColor;
}

.form-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: currentColor;
}

.checkbox-card {
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid #dedede;
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 100ms all ease;
  margin: 0.5rem;

  &:hover {
    border-color: rgba(var(--color-main-500), var(--text-opacity));
  }
}

.checkbox-card-selected {
  border-color: rgba(var(--color-main-500), var(--text-opacity)) !important;
  background-color: rgba(var(--color-main-100), var(--text-opacity)) !important;
}

.radio-btn {
  width: 1.2rem;
  height: 1.2rem;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 50%;
  padding: 4px;
  margin-right: 0.4rem;
  box-shadow:
    0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 2px 5px 0px rgba(48, 49, 61, 0.08);
}

.radio-btn.checked {
  border-color: rgba(var(--color-main-500), var(--text-opacity));
  background-color: rgba(var(--color-main-500), var(--text-opacity));

  > div {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: #fff;
  }
}

.authorized-users-badge {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(var(--color-main-500), var(--text-opacity));
  color: #fff;
  top: -0.4rem;
  left: 1.6rem;
  width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.7rem;
  font-weight: 500;
}

// Ag grid

.ag-sparkline-tooltip-title {
  display: block;
  font-weight: bold;
  border-bottom: 1px solid $gray-300;
  padding: 0 0.3rem;
}

.ag-sparkline-tooltip-content {
  display: block;
  padding: 0 0.3rem;
}

.ag-header-cell:not(.ag-right-aligned-header) {
  padding-right: 0;

  .ag-cell-label-container {
    justify-content: start;
  }

  .ag-header-cell-label {
    flex: unset;
  }
}

.ag-cell-wrapper {
  height: 100% !important;
  display: flex;
  align-items: center;
}

.ag-cell.ag-right-aligned-cell {
  padding-left: 0;
}

.ag-right-aligned-header {
  &.ag-header-cell {
    padding-left: 0;
  }

  .ag-cell-label-container {
    justify-content: end;
  }

  .ag-header-cell-label {
    flex: unset;
  }
}

.cell-editable {
  cursor: url("/assets/img/chart_icons/edit-svgrepo-com.svg"), text;

  &:hover {
    outline: 1px dashed #2196f3;
  }
}

.invalid-feedback {
  display: block;
}

.rotate-270 {
  transform: rotate(-90deg);
}

.btn-icon-sm {
  width: 0.8rem !important;
  height: 0.8rem !important;
}

.pill {
  transition: 200ms all ease;
  display: inline-flex;
  padding: 0.2rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid $gray-500;
  font-weight: 500;
  cursor: pointer;

  img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
  }
}

.pill-success {
  border-color: $success;
  background-color: $gray-50;
  color: $success;

  img {
    filter: invert(63%) sepia(17%) saturate(1094%) hue-rotate(117deg) brightness(92%) contrast(89%);
  }

  &:hover:not(.pill-no-hover) {
    background-color: $light-success;
  }
}

.pill-info {
  background-color: $gray-100;
  color: $gray-500;
  // gray-500
  img {
    filter: invert(73%) sepia(0%) saturate(366%) hue-rotate(155deg) brightness(91%) contrast(78%) !important;
  }

  &:hover:not(.pill-no-hover) {
    background-color: $gray-200;
  }
}

.pill-sm {
  font-size: 0.8rem;
  line-height: 0.9rem;
}

.modal-xxl {
  max-width: 90vw;
}

.coding-font {
  font-family:
    Consolas,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New,
    monospace;
}

.input-section {
  display: flex;
  flex-direction: column;

  label {
    margin-left: 0.5rem;
  }

  .counter {
    font-size: var(--text-sm);
    color: $gray-600;
    display: inline-flex;
    justify-content: flex-end;
    margin: 0.5rem 0.5rem 0 0;
  }

  .error,
  .hint {
    padding-left: 0.5rem;
    padding-top: 0.3rem;
    font-size: 0.8rem;
  }

  .error {
    color: $danger;
  }
}

.modal-plain {
  min-width: 98vw;
}

.obfuscation-mode-on .sensitive-data {
  background-color: #f0f3f5;
  filter: blur(5px);
}
